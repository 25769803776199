.input-group{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: var(--primary-color);
    font-size: larger;
}

.input-group-item{
    background: none;
    border: none;
    text-align: center;
}

.input-group-item label{
    margin-top: 0.5rem;
}

.button-change{
    padding: 0.1rem;
    background: var(--secondary-color);
    border: none;
    color: white;
    padding: 0.2rem 1.5rem;
    border-radius: 0.4rem;
}

.btn-account-detalhes{
    background: var(--secondary-color);
    padding: 0.8rem;
    margin-bottom: 0.5rem;
    color: white;
    border: none;
    border-radius: 0.5rem;
}

.btn-account-detalhes:hover{
    background: var(--focus-color);
}

.go-back-btn{
    position: relative;
    top:2rem;
    left: 2rem;
    color: var(--secondary-color);
    justify-content: center;
    align-items: center;
}

.account-detalhes-wrapper{
    color: var(--primary-color);
    font-size: small;
    justify-content: center;
    text-align: center;
    margin-bottom: 2rem;
}