.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(204, 204, 204, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }
  
  .modal-content {
    width: 14rem;
    height: 14rem;
    background-color: white;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 15px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    color: var(--primary-color);
  }

  .modal-logo{
    color: var(--secondary-color);
  }

  .modal-cancel-btn{
    background-color: var(--focus-color);
    color: white;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.15rem;
    border-radius: 2rem;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
    top: -2.2rem;
    left: 12rem;
  }
  
  .modal-buttons-wrapper {
    margin-top: 10px;
}

.modal-buttons-wrapper button {
    border: none;
    border-radius: 1.5rem;
    margin: 0 5px;
    padding: 0.7rem;
    width: 6rem;
    color: white;
    cursor: pointer;
}

.btn-ok{
    background: var(--focus-color);
    cursor: pointer;
}

.btn-no{
    background: var(--secondary-color);
    cursor: pointer;
}
  
  