/* Agrupa quantidade e o botão Adicionar em linha */
.market-quantity-add-group {
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
}

/* Aumentar o tamanho do texto da etiqueta de quantidade */
.market-quantity-label {
  margin-top: 5%;
  font-size: 20px;
  font-weight: bold;
  margin-right: 1rem; /* Para dar espaçamento entre o texto e o input */
}

/* Estilo para o input de quantidade */
.market-quantity-input {
  font-size: 18px;
  width: 60px;
  text-align: center;
  margin-right: 10px; /* Espaço entre input e botões */
}

/* Estilo para os botões de aumentar/diminuir */
.market-quantity-buttons2 span {
  font-size: 20px; /* Aumenta o tamanho dos botões + e - */
  cursor: pointer;
  padding: 5px 10px;
  margin: 0 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #f1f1f1;
  display: flex;
  flex-direction: column;
}

.market-quantity-buttons2 span:hover {
  background-color: #ddd;
}

/* Botão Adicionar ao lado da quantidade */
.market-add-button2 {
  font-size: 20px;
  font-weight: bold;
  padding: 1%;
  background-color: var(--primary-color);
  color: white;
  border: none;
  cursor: pointer;
  margin-left: 5%;
  margin-block-end: 7%;
}

.market-add-button2:hover {
  background-color: #218838;
}

/* Botão cancelar separado */
.market-cancel-button {
  margin-top: 1.5rem;
  text-align: center;
}

.market-cancel-button button {
  font-size: 16px;
  padding: 8px 16px;
  background-color: #6c757d;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.market-cancel-button button:hover {
  background-color: #5a6268;
}
