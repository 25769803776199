/*.data-produtores-container{
    display: flex;
    flex-direction: column;
    margin-top: 0;
    width: 90%;
    align-items: center;
    padding-bottom: 1.5rem;
}*/
.data-produtores-container {
  display: flex;
  align-items: flex-start;
  gap: 20px;
}


.producer-informacao{
  text-align: left;
  margin-top: -5%;
}

.producer-informacao2{

  
  
  text-align: left;
}
.producer-info p, .col-7 div {
  margin: 5px 0;
}
.total{
  width: 97%;
}

.producer-category-wrapper{
    display: flex;
    align-items: center;
    width: 100%;
    height: 50px;
    justify-content: flex-start;
   
}

.producer-category-wrapper span {
    width: 16px; /* Example fixed width */
    height: 16px; /* Example fixed height, should be the same as width */
    min-width: 16px; /* Prevents the flex container from shrinking it */
    min-height: 16px;
    max-width: 16px; /* Prevents the flex container from growing it */
    max-height: 16px;
    border-radius: 50%; /* Circular shape */
    flex-shrink: 0; /* Prevents the flex item from shrinking */
  }


  /* Tables */

.table-row-height-45{
    height: 40px;
   
  }

  