.top-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    z-index: 500;
    
  }

  .notification-icon,
  .exit-icon {
    margin: 1rem 1rem;
    cursor: pointer;
    color: var(--primary-color)
  }

  .exit-icon {
    margin-right: 3rem;
  }

  .graphical-center-item {
    background: var(--secondary-color);
  }

  .new-messages-indicator{
    width: 1rem;
    height: 1rem;
    background: var(--focus-color);
    color: white;
    justify-content: center;
    text-align: center;
    align-items: center;
    position: relative;
    top: -1.5rem;
    left: 2.2rem;
    border-radius: 50%;
    font-size: x-small;
    display: inline-block;

  }

  .slide-from-left {
    animation: slideFromLeft 0.5s ease-in-out forwards;
    position: relative;
    left: -100%;
  }
  
  .slide-from-right {
    animation: slideFromRight 0.5s ease-in-out forwards;
    position: relative;
    right: -100%;
  }
  
  @keyframes slideFromLeft {
    100% {
      left: 0;
    }
  }
  
  @keyframes slideFromRight {
    100% {
      right: 0;
    }
  }
  