.floating-menu {
    display: flex;
    flex-direction: row;
    text-align: center;
    position: fixed;
    bottom: 0rem;
    left: 0;
    right: 0;
    margin: 0 auto; 
    justify-content: center;
    align-items: center;   
    height: 3.8rem;  
    background: var(--primary-color);  
    margin-bottom: 2.5rem; 
    border-radius: 5rem;
    width: 60%;
    justify-content: space-evenly;
    padding-left: 0.9rem;
    padding-right: 0.9rem;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    z-index: -10;
  }

  .floating-menu-item {
    cursor: pointer;
    color: white
  }

  .active{
    border-radius: 5rem;
    padding: 0.4rem;
    margin: 0;
    background: var(--focus-color);
  }

.basket-count{
  position: relative;
  margin: 0;
  top: -0.9rem;
  right: -1.4rem;
  background: var(--focus-color);
  border-radius: 50%;
  display: inline-block;
  width: 1.2rem;
  height: 1.2rem;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-size: small;
}

.basket-count-negative{
  position: relative;
  top: -0.9rem;
  right: -1.4rem;
  background: var(--primary-color);
  border-radius: 50%;
  display: inline-block;
  width: 1.2rem;
  height: 1.2rem;
  justify-content: center;
  text-align: center;
  align-items: center;
  font-size: small;
}

.slide-from-bottom {
  animation: slideFromBottom 0.5s ease-in-out forwards;
  position: relative;
  bottom: -50px; /* Adjust as needed */
}

@keyframes slideFromBottom {
  100% {
    bottom: 0;
  }
}
