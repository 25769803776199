.generic-success-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(204, 204, 204, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .generic-success-modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    text-align: center;
    position: relative;
  }
  
  .modal-title {
    color: var(--secondary-color);
    font-size: 1.2rem;
    font-weight: bold;
    margin: 1rem 0rem;
  }
  
  .generic-success-message {
    margin: 20px 0;
    color: var(--primary-color);
  }
  
  .modal-button {
    padding: 10px 20px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .generic-success-modal-close{
    background-color: var(--focus-color);
    border: none;
    color: white;
    width: 1.9rem;
    height: 1.9rem;
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -0.8rem;
    right: 1rem;
    cursor: pointer;
  }
  