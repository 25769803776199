.dashboard-container{
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 50vh;
}

.btn{   
    color: white;
    padding: 1.5rem;
    border-radius: 2rem;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.25rem 0rem;
    height: 4rem;
}
.btn-market,.btn-warehouse{
    background: var(--primary-color); 
}

.btn-account{
    background: var(--secondary-color);
}

.btn-order, .btn-basket{
    background: var(--focus-color); 
}

.basket-container{
    display: inline-block;
    padding: 0.5rem;
    margin: 1rem;
    background-color: white;
    border-radius: 0.5rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.basket-list{
    display: flex;
    flex-direction: row;
    padding: 0.4rem 0.3rem;
    justify-content: space-between;
}

.basket-list-info{
    font-size: x-small;
    display: flex;
    flex-direction: column;
    
}

.basket-list-info .description{
    margin-left: 0.8rem;
}

.basket-list-title{
    color: var(--primary-color);
    font-size: medium;
    font-weight: 600;
    margin-top: -0.5rem;
}

.basket-list-title span{
    background: var(--focus-color);
    width: 0.3rem;
    height: 1.2rem;
    position: relative;
    top: 0.3rem;
    margin-right: 0.5rem;
    display: inline-block;
    color: var(--focus-color);
}

.basket-list-purchase{
    text-align: right;
    margin-top: 1rem;
}

.basket-list-purchase .total{
    margin-top: 0.5rem;
}

.remove-item-btn{
    background: var(--primary-color);
    color: white;
    border: none;
    font-size: x-small;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.2rem;
    position: relative;
    top: 0;
}


.quantity-item-btn{
    background: var(--focus-color);
    color: white;
    border: none;
    font-size: x-small;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.2rem;
    position: relative;
    top: 0;
}