.user-table thead th:nth-child(1){
    width: 5%;
}
.user-table thead th:nth-child(2){
    width: 10%;
}
.user-table thead th:nth-child(3){
    width: 10%;
}
.user-table thead th:nth-child(4){
    width: 5%;
}
.user-table thead th:nth-child(5){
    width: 10%;
}
.user-table thead th:nth-child(6){
    width: 10%;
}
.user-table thead th:nth-child(7){
    width: 20%;
}
.user-tables-container-wrapper {
    width: 95%;
}
.map-table-container{
   width: calc(100vw - 19rem);
   height: calc(100vh - 120px);
   background: white;
   border-radius: 0.5rem;
   padding: 0;
   box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1);
}


.map-table{
    min-height: 0;
   width: 100%;
   
  
}

.map-table table {
    margin-bottom: 0.5rem;
}

.map-table {
    width: 100%;
    border-collapse: collapse;
    margin: 20px 0;
    background: white;
  }
  
  .map-table th,
  .map-table td {
    text-align: left;
    padding: 8px;
  }
  
  .map-table th {
    background-color: #f2f2f2;
  }

  .map-table tr {
   cursor: pointer;
   
   }
  
  .map-table tr:nth-child(even) {
   border-top: 1px solid lightgray;
   border-bottom: 1px solid lightgray;
  }
  
  .map-table tr.selected-row {
   border-radius: 0.5rem;
   border: 1px solid var(--focus-color);
  }

  .map-table tr.selected-row:first-child { 
    border-bottom: none;
   }
   .map-table tr.selected-row:last-child { 
    border-top: none;
   }
  
  .map-table select {
    padding: 4px;
    margin: 0 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  .map-table button {
    padding: 3% 1.0rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: var(--secondary-color);
    color: white;
  }
  
  .map-table button:hover {
    background-color: var(--focus-color);
  }
  
  
  .map-card-table-header {
    background-color: var(--secondary-color);
    border-radius: 0.5rem 0.5rem 0 0;
    text-align: center;
    padding: 0.5rem 0rem;
    font-weight: bold;
    color: white;
    min-width: 0; /* Dark text for contrast */

  }
  
  .map-card-table-body {
    width: 100%;
    overflow-x: auto; /* Ensure table is scrollable on smaller screens */
  }
  
  .map-table-scroll-container {
    overflow-x: auto; /* Additional scroll container for better control */
    padding: 0 15px; /* Padding to avoid touching the edges */
    height: 94%;
    overflow: hidden;
    overflow-y: scroll;
  }
  
  /* Ensure table width is properly set within the scroll container */
  .table-scroll-container .map-table {
    width: auto;
    min-width: 100%; /* Ensure table takes up at least the full width of the container */
  }
  

  .order-spinner {
    position: absolute;
    top: 55%;
    left: 55%;
  }

  .distance-info {
    background: rgba(255, 255, 255, 0.8);
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 1px 5px rgba(0, 0, 0, 0.2);
    position: relative;
    bottom: 7rem;
    left: 10px;
    z-index: 1000; /* Ensure it's above other map layers */
    width: 22rem;
  }
  
  .route-legend {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
  
  .route-legend:last-child {
    margin-bottom: 0;
  }
  
  .route-color {
    width: 20px;
    height: 10px;
    display: inline-block;
    margin-right: 5px;
  }
  
  .producer-to-transformer {
    background-color: var(--secondary-color);
  }
  
  .transformer-to-customer {
    background-color: var(--focus-color);
  }
  
 
  .route-distance {
    margin-left: auto;
    padding-left: 10px;
    font-weight: normal;
  }
  