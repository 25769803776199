/* EditUserModal.css */
.edit-user-modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(204, 204, 204, 0.2); /* Adicione um fundo semi-transparente para destacar o modal */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Coloca o modal na frente do conteúdo */
  }
  .close-button2 {
    background-color:white;
    color:#5D643F;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    text-align: right;
  }

  
  .edit-user-modal {
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-width: 80%; /* Largura máxima do modal */
  }
  
  /* Estilos para inputs, botões, etc. */