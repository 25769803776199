.market-group{
  text-align: center;
  padding: 1.5rem 0rem;
}
.product-card {
  display: inline-block;
  width: 10rem; 
  height: 10rem ;
  margin: 1%;
  padding: 0.5rem;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.product-card .type {
  font-size: 16px;
  font-weight: lighter;
  color: var(--text-color);
  margin-bottom: 5px;
}

.product-card .price {
  font-size: 14px;
  margin-bottom: 10px;
}

.product-card .market-btn{
  background: var(--secondary-color);
}

.product-card .market-btn:hover{
  background: var(--focus-color);
}

.product-wrapper{
  padding: 1rem 2rem;
}


.quantity-controls{
  display: flex;
  flex-direction: row;
  justify-content: center;
}


.quantity-controls .input-group {
  display: flex;
  flex-direction: row;
}

.quantity-controls .quantity-input {
  width: 4rem;
  height: 3.5rem;
  font-size: larger;
  text-align: center;
  margin-right: 0.2rem;
  margin-left: 1rem;
  border-radius: 0.5rem;
  border: 1px solid;

}

.quantity-controls .quantity-buttons{
  display: flex;
  flex-direction: column;
  padding: 0.2rem;
  margin-left: 0.5rem;
}

.quantity-controls .quantity-buttons button {
  width: 1.7rem;
  height: 1.7rem;
  font-size: 1.2rem;
  display: inline-block;
  font-weight: bold;
  border-radius: 50%;
  border: none;
  color: white;
  background: var(--focus-color);
  margin: 0.1rem 0rem;

  text-align: center;
  align-items: center;
}

.quantity-button-control {
  background: var(--focus-color);
  cursor: pointer;
  width: 1.7rem;
  height: 1.7rem;
  display: flex; /* Changed from inline-block to flex */
  font-weight: bold;
  border-radius: 50%;
  padding: 0.1rem;
  border: none;
  color: white;
  text-align: center;
  justify-content: center; /* This will work now */
  align-items: center; /* This will work now */
  margin: 0.1rem 0rem;
  font-size: 1.2rem;
  font-weight: bold;
}

.select-buttons button{
  font-size: large;
}


.market-container-center{
  display: flex;
  flex-direction: column;
  align-items: center;
 justify-content: center;
 padding: 5rem 0rem;
}