
.map-container {
      display: flex;
      background: white;
      border-radius: 1rem;
      justify-content: center;
      align-items: center;
      height: calc(100vh - 115px);
      flex: 1;
      padding:0;
      border: 2px solid white;
      box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1);
  }
  .square-box {
   
    border: 2px solid #E6E6E6; /* Adicione uma borda de 2px preta (ou a cor desejada) */
    padding: 10px; /* Adicione preenchimento interno, se necessário */
    /* Outros estilos, como cores de fundo ou margens, conforme necessário */
  }

  

  .tr-produtores:hover {
    background-color: #f5f5f5;
  }




.tables-container {
    display: flex;
    flex-direction: column;     
    padding-top:0;
    padding: 0;
    margin-left: 2rem; 

   
  }
  
  .card-table {
    border: none;
    height: max-content;
    margin: 0;
    margin-bottom: 1rem;
    border-radius: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
   
   
  }

  .tables-container .card-table:last-child {
    margin-bottom: 0; 
  }

  
  .card-table-header{
    background: var(--secondary-color);
    padding: 0.5rem;
    text-align: center;
    color: white;
    border-radius: 1rem 1rem 0rem 0rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

  }
  
  .card-table-button{
    display: flex;
    justify-content: right;
    align-items: center;  
  }

  .card-table-button-add{
    display: flex;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    border: none;
    background: var(--focus-dark-color);
    padding: 0.2rem;
    color: white;
    justify-content: center;
    align-items: center;
    margin-right: 0.7rem;
    transform: scaleX(-1);
    
  }

  .card-table-button-add:hover{
    transform: scale(-1.1);
    font-size: large;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  .card-table-body{
    display: flex;
    flex-direction: column;
    background: white;
    padding: 0.5rem;
    text-align: center;
    color: var(--secondary-color);
    border-radius:  0rem 0rem 1rem 1rem;
    height: max-content;
    justify-content: center;
    align-items: center;
    cursor: default;
  }

  .btn-detalhes{
    background: var(--focus-color);
    color: white;
    border: none;
    border-radius: 0.2rem;
    padding: 0.3rem 01rem;
    cursor: pointer;
    font-size: small;
  }

  .btn-detalhes:hover{
    transform: scale(1.05);
  }

  
  .table-row{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
  }

 
  .table-header-cell{
    width: 15%;
    text-align: left;
    padding: 0rem 0.5rem;
  }
  
  .table-cell{
    width: 15%;
    text-align: left;
    color: var(--text-color);
    font-size: small;
    padding: 0rem 0.5rem;
  }

  .table-cell-nome{
    width: 30%;
    text-align: left;
    color: var(--text-color);
    font-size: small;
    padding-left: 0.5rem;
  }

  .table-cell-nome:hover{
    border-left: 5px solid var(--secondary-color);
  }

  .table-cell:first-child {
    font-weight: bold;
    color: var(--primary-color);
    font-size: small;
  }

  
  .previous-btn, .next-btn{
    border: none;
    background: var(--focus-color);
    color: white;
    border-radius: 50%;
    display: inline-block;
    padding: 0.2rem;
    margin: 0rem 0.5rem;
    cursor: pointer;
    justify-content: center;
    text-align: center;
    align-items: center;
    height: 1.2rem;
    width: 1.2rem;
  }
  
  .previous-btn:hover, .next-btn:hover{
    background:var(--focus-dark-color);
  }

  .spinner-container {
    display: flex;
    justify-content: center; 
    align-items: center; 
    height: 100%; 
  }
  

  .filter-clean-button{
    color: white;
    background: var(--focus-dark-color);
    border: none;
    padding: 0.2rem 1.5rem;
    border-radius: 0.2rem;
    cursor: pointer;
  }

  .card-table-body-filter input, select{
    color: gray;
    border: 1px solid lightgray;
    border-radius: 0.2rem;
    padding: 0.1rem 0.5rem;
    font-size: small;
  }

  .card-table-body-filter{
    display: flex;
    flex-direction: row;
    background: white;
    padding: 0.5rem 1rem;  
    color: var(--secondary-color);
    border-radius:  0rem 0rem 1rem 1rem;  
    justify-content: space-between;
    align-items: center;

  }
  /* .table-body-row{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    border: 1px solid red;
  } */

.table-body-row:hover {
 background:  var(--secondary-color);
 cursor: default;
 border-radius: 1rem;
 color: whitesmoke;
 border:none;
}


.selected-row {
  border-left: 5px solid var(--focus-color); 
  font-weight: bold;
}

.selected-row-data{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
  font-size: small;
}

.erase-btn, .edit-btn, .metric-btn{
  cursor: pointer;
  border: none;
  margin: 0.5rem;
  padding: 0.2rem 1.5rem;
  color: white;
  border-radius: 0.3rem;
}

.erase-btn{
  color: darkgrey;
}

.edit-btn{
  background-color: var(--secondary-color);
}

.metric-btn{
  background-color: var(--focus-dark-color);
}

.erase-btn:hover, .edit-btn:hover, .metric-btn:hover{
  transform: scale(1.05);
}

.erase-btn:disabled, .edit-btn:disabled, .metric-btn:disabled{
  transform: scale(1);
  background-color: lightgray;
  color: white;
  cursor: default;

}

.stock-cell{
  background: var(--secondary-color);
  border-radius: 0.3rem;
  color: white;
}


  .filter-clean-button:hover{
    background: var(--focus-color);
  }
  
  .card-table-title{justify-content: left;}

  @media only screen and (min-width: 750px) {
  
    .map-container {
      margin-right: -0.2rem;
      margin-left: 0.8rem;
      margin-bottom: 1rem;
    
    }

    .tables-container{
    padding-left: 0;
    margin-left: 0;
    }


  }

  @media (max-width: 768px) {
    .map-container {
      margin-bottom: 1rem;
      height: 500px;
    }

    .tables-container{
    padding-left: 0.1rem;
    padding-right: 0.1rem;
    margin-left: -1rem;
    margin-right: -1rem;
    }

    .card-table{
      overflow-x: scroll;
    }
  }
  