.warning-popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(238, 116, 22, 0.92);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; 
  }
  
  .warning-popup {
    background-color: white;
    padding: 35px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    text-align: center;
    z-index: 1001;
    border: 3px solid var(--primary-color);
  }
  

  