.sidebar {
  width: 240px;
  background-color: var(--background-color);
  padding: 10px 20px;
  height: 100vh; /* Ocupa a altura total da tela */
  overflow-y: auto; /* Adiciona barra de rolagem se o conteúdo ultrapassar o limite */
  padding-right: 10px;
  position: relative; /* Necessário para o posicionamento absoluto do .sidebar-buttons */
}


.submenu, .nested-submenu {
  max-height: 400px; /* Limita a altura do submenu */
  overflow-y: auto; /* Permite rolagem se o conteúdo for maior que o espaço disponível */
  padding-right: 10px; /* Ajusta o espaçamento interno */
}

.sidebar-buttons {
  position: absolute; /* Fixa o elemento na parte inferior da barra lateral */
  bottom: 0;
  width: 90%; /* Ocupa toda a largura disponível */
  height: 20%;
  /*padding: 10px;*/
  background-color: var(--background-color); /* Mantém o fundo igual ao resto da sidebar */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Adiciona um leve efeito de sombra */
  z-index: 10;
}

.sidebar-title {
  background: var(--focus-dark-color);
  color: white;
  margin-right: 2rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.menu-item {
  margin-bottom: 20px;
  cursor: pointer;
  color: var(--primary-color) !important;
  font-weight: 600;
}

.menu-item-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: var(--text-color);
}

.square-item {
  background: var(--focus-color);
  width: 0.5rem;
  height: 1.5rem;
  display: block;
  margin-right: 0.5rem;
}

.submenu {
  margin-left: 15px;
  display: none;
}

.submenu.expanded {
  display: block;
}

.nested-submenu {
  margin-left: 30px;
  display: none;
}

.nested-submenu.expanded {
  display: block;
  font-weight: normal;
  max-height: 400px; /* Limita a altura do nested submenu */
  overflow-y: auto; /* Permite rolagem */
  transition: max-height 0.3s ease-in-out;
}

.submenu-item.active + .nested-submenu.expanded {
  max-height: 400px;
}

.submenu-item:hover {
  color: var(--focus-color);
}

.nested-submenu span {
  display: block;
  margin: 5px 0;
  cursor: pointer;
}

.nested-submenu span:hover {
  color: var(--focus-color);
}

.submenu-item {
  position: relative;
  padding: 2px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
  color: var(--secondary-color);
  margin-right: 2rem;
}

.submenu-item.expanded {
  color: var(--primary-color);
  margin-right: 2rem;
}

.nested-submenu.expanded {
  max-height: 400px;
  transition: max-height 0.3s ease-in-out;
}

.submenu-item.active {
  background-color: var(--secondary-color);
  color: white;
  border-radius: 5px;
  padding: 2px 10px;
  margin-right: 2rem;
  box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.1);
}

.submenu-sub-item.active {
  background-color: var(--focus-color);
  color: white;
  border-radius: 5px;
  padding: 2px 10px;
  box-shadow: inset 0 4px 8px rgba(0, 0, 0, 0.1);
}

.submenu-sub-item.active:hover {
  color: white;
}

.sidebar.mobile {
  display: none;
}

.sidebar-buttons .sidebar-title {
  background-color: var(--secondary-color);
  text-transform: uppercase;
  padding: 0.2rem;
  margin-bottom: 0.2rem;
}

.sidebar-buttons:hover .sidebar-title:hover {
  background-color: var(--focus-color);
}

.fade-in {
  animation: fadeIn 0.5s ease forwards;
}

.fade-out {
  animation: fadeOut 0.5s ease forwards;
}

.market-cesto {
  position: relative;
  display: flex;
}

.market-basket-count {
  position: absolute;
  top: -0.3rem;
  right: 0;
  border-radius: 50%;
  justify-content: center;
  text-align: center;
  display: inline-block;
  width: 1.2rem;
  height: 1.2rem;
  background-color: var(--primary-color);
  font-size: small;
  color: white;
}

@keyframes fadeIn {
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
}

@keyframes fadeOut {
  from { opacity: 1; transform: translateY(0); }
  to { opacity: 0; transform: translateY(-20px); }
}
