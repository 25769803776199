.add-bolota-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(204, 204, 204, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .add-bolota-modal-content {
    position: relative;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  
  .add-bolota-modal-close {
    background-color: var(--focus-color);
    border: none;
    color: white;
    width: 1.9rem;
    height: 1.9rem;
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: -0.8rem;
    right: 1rem;
    cursor: pointer;
  }
  
  .add-bolota-modal-title {
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .add-bolota-modal-form {
    margin-top: 1rem;
  }
  
  .add-bolota-dropdown {
    position: relative;
    display: inline-block;
    width: 100%;
  }
  
  .add-bolota-dropdown-toggle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    box-sizing: border-box;
    cursor: pointer;
    background-color: white;
    border: 1px solid var(--background-color);
    border-radius: 0.8rem;
  }
  
  .add-bolota-dropdown-options {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background: white;
    border: 1px solid var(--primary-color);
    border-top: none;
  border-radius: 0 0 4px 4px;
  display: none;
  transition: display 0.3s ease;
}

.add-bolota-dropdown:hover .add-bolota-dropdown-options {
  display: block;
  border: 1px solid var(--background-color);
  border-top: none;
  margin-top: -0.6rem;
  margin-left: -0.05rem;
  border-radius: 0rem 0rem 0.5rem 0.5rem;
}

.add-bolota-dropdown-option {
  padding: 10px;
  cursor: pointer;
  text-align: left;
}

.add-bolota-dropdown-option:hover {
    background-color: var(--focus-color);
    color: white;
  }

.add-bolota-input {
  display: flex;
  padding: 0.8rem;
  margin: 5px 0;
  background-color: white;
  border: 1px solid var(--background-color);
  border-radius: 0.8rem;
}

.add-bolota-modal-buttons {
  margin-top: 20px;
}

.add-bolota-button {
  width: 100%;
  padding: 10px;
  background-color: var(--primary-color);
  color: white;
  border: none;
  border-radius: 0.8rem;
  cursor: pointer;
}

.add-bolota-button:hover {
  background-color: var(--focus-color);
}

.default-button:disabled{
  background-color: lightgray;
  cursor:not-allowed
}

.label{
  display: flex;
  flex-direction: row;
  justify-content: center;

}

.label input{
  text-align: center;
}