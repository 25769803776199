

.map-geocoder-wrapper {
  width: 105%;
  height: 350px;
  padding: 0;
  margin: 0;
  position: relative;
  top: 0;
  left: 0;
  border-radius: 0.5rem;
  z-index: 0;
  border: 2px solid white;
 
  }
