.dashboard-container{
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 50vh;
}

.btn{   
    color: white;
    padding: 1.5rem;
    border-radius: 2rem;
    border: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.25rem 0rem;
    height: 4rem;
}
.btn-warehouse{
    background: var(--primary-color); 
}

.btn-account{
    background: var(--secondary-color);
}

.btn-order{
    background: var(--focus-color); 
}
.warehouse-container {
    display: flex;
    flex-direction: column;
    padding: 2rem;
  }
  
  .warehouse-content {
    display: flex;
    flex-direction: row;
    padding: 1rem;
    justify-content: center;
  }
  
  .warehouse-content-name {
    display: flex;
    color: var(--primary-color);
    margin-top: 1rem;
  }
  
  .warehouse-input-group .warehouse-input-item {
    display: flex;
    flex-direction: column;
    font-size: large;
    height: 3rem;
    text-align: center;
  }

  .warehouse-input-item{
    position: relative;
    top:-1.5rem;
  }
  
  .input-item-quantity {
    padding: 0.5rem;
    border-radius: 1.2rem 1.2rem 0rem 0rem;
    border: 1px solid var(--primary-color);
    background-color: white;
    color: var(--primary-color);
    border-bottom: none;
    font-weight: bold;
  }
  
  .input-item-price {
    padding: 0.5rem;
    border-radius: 0rem 0rem 1.2rem 1.2rem ;
    border: 1px solid var(--primary-color);
    background-color: var(--focus-color);
    color: white;
    border-top: none;
    font-size: small;
  }
  
  .warehouse-total{
    display: flex;
    flex-direction: row;
    font-size: larger;
    color: var(--primary-color);
    text-align: center;
    justify-content: center;
    align-items: baseline;
    margin-top: 3rem;
  }

  .warehouse-add-btn{
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .warehouse-content-center{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 50vh;
  }

  .edit-stock-btn{
    position: relative;
    top: 1.9rem;
    right: -7.6rem;
    background: var(--secondary-color);
    color: white;
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
  }