.warehouse-history-container {
    display: flex;
    flex-direction: column;

    margin-left: 3rem;

}

.card-title{
  background-color: var(--secondary-color);
  padding: 1rem;
  border-radius: 0.5rem;
  color: white;
  text-transform: uppercase; 
}

.producer-info-card{
  margin-right: 3rem;
}

.orders-history-container .card-title{
  background: var(--secondary-color);
}
.orders-history-container .warehouse-history-header{
  background: var(--primary-color);
}

.warehouse-history-header {
    display: flex;
    background: var(--primary-color);
    color: white;
    padding: 0.5rem;
    margin-bottom: 0.1rem;
    border-radius: 0.3rem;
    
}

.warehouse-history-body{
  height: 480px;
  overflow-y: scroll;
}

.warehouse-history-body::-webkit-scrollbar{
  display: none;
}

.warehouse-history-item {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    margin-bottom: 0.2rem;
    border: 1px solid #ccc;
    border-radius: 0.3rem;
    background-color: white;
}

.warehouse-history-cell {
    flex: 1;
    text-align: center;
}

.stock-bolota-container {
 
  max-height: 200px;
  overflow-y: auto;
  margin-bottom: 1rem;
}

.stock-bolota-header, .stock-bolota-item {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem;
}

.stock-bolota-header {
  display: flex;
  background: var(--primary-color);
  color: white;
  padding: 0.5rem;
  margin-bottom: 0.3rem;
  border-radius: 0.3rem;
}

.stock-bolota-item {
  border-radius: 0.5rem;
  border-bottom: 1px solid #eee;
  background-color: #f9f9f9;
}

.stock-bolota-item:last-child {
  border-bottom: none;
}

.stock-bolota-cell {
  margin-right: 0.5rem;
  white-space: nowrap;
}





/* Responsive Design */
@media only screen and (max-width: 750px) {
    .warehouse-history-cell {
        text-align: left;
        padding: 0.1rem;
    }
    .warehouse-history-item, .warehouse-history-header {
        display: block;
    }
    .stock-bolota-cell {
      flex: 1; /* Each cell takes equal width */
      margin-right: 0.2rem;
      font-size: 0.9em; /* Smaller font size on small screens */
    }
    .stock-bolota-item {
      flex-wrap: wrap; /* Allow cells to wrap on small screens */
    }
}


/* Additional Styles */
.breadcrumb-wrapper {
  border-left: 5px solid var(--focus-color);
  color: var(--secondary-color);
}

.breadcrumb-item {
  margin: 0rem 0.1rem;
  cursor: pointer;
}

.breadcrumb-item:hover {
  color: var(--focus-color);
}

.breadcrumb-item:last-child {
  color: var(--focus-color);
}

.breadcrumb-item:last-child:hover {
  color: var(--secondary-color);
  cursor: default;
}

.card-body button:hover{
  background: var(--secondary-color) !important;
}

.metrics-card{
  width: 10rem;
  height: 10rem;
  background: #f9f9f9;
  border-radius: 0.5rem;
  margin-top: 2rem;
  color: var(--secondary-color);
  font-size: xx-large;
  justify-content: space-around;
  text-align: center;
  align-items: center;
  box-shadow: var(--secondary-color) 2px 2px 2px; 
  transition: transform 0.1s ease;
  cursor: pointer;
  border: 1px solid white
}

.metrics-card:hover{
  transform: translateY(-10px) ;
  border: 1px solid var(--focus-color);
}

.metrics-card > p{
  font-size: small;
  color: var(--primary-color);
  padding-top: 1rem;
}

.metrics-card:last-child{
  background: var(--focus-color);
  color: white;
  border: 1px solid var(--focus-color);
}