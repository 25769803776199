.delete-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(204, 204, 204, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .delete-modal-content {
    background-color: white;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    text-align: center;
    position: relative;
    height: 10rem;
    width: 15rem;
    display: flex;
    flex-direction: column;

  }
  
  .delete-modal-content p {
    color: var(--secondary-color);
    font-size: 1.2rem;
    font-weight: normal;
    margin: 1rem 0rem;
    margin-right: 1rem;
    position: absolute;
    
  }
  
  .delete-success-message {
    margin: 20px 0;
    color: var(--primary-color);
  }
  
  .delete-modal-buttons {
    width:82%;
    position: absolute;
    bottom: 2rem;
    font-size: small;
    justify-content: center;
   text-align: center;
   padding: 0.5rem;
   display: flex;
   gap: 2rem;
   transition: all 0.3s ease;
  }

  .delete-modal-buttons button:first-child{
    background-color: var(--focus-color) !important;
    font-size: small;
    width: 5rem;

  }

  .delete-modal-buttons button:last-child{
    background-color: var(--secondary-color) !important;
    font-size: small;
    width: 5rem;
  }
  
  .delete-modal-buttons button:hover{
   transform: scale(1.05);
  }
  
  .delete-modal-close-button{
    background-color: var(--focus-color) !important;
    border: none;
    color: white;
    width: 1.9rem;
    height: 1.9rem;
    border-radius: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    margin-top: 5%;
    right: 1rem;
    top:-1.5rem;
    cursor: pointer;
  }
  