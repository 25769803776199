.order-list-container{
    padding: 1rem;
    display: flex;
    flex-direction: column;

}  
  .order-row {
    background: white;
    border: 1px solid var(--background-color);   
    align-items: center;
    cursor: pointer;
    transition: background-color 0.2s;
    border-radius: 0.5rem;
    color: var(--text-color);
    margin-bottom: 0.2rem;
  }
  
  .order-row:hover {
    border: 1px solid var(--focus-color);
  }
  
  .order-summary {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;

  }

 
  
  .order-summary-left {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    font-size: small;
    padding: 0.5rem;
  }
  
  .order-summary-right {
    display: flex;
    flex-direction: row;
    justify-content: center;  
    background: none;
    text-align: right;
  }

  .order-summary-quantity{
    background: var(--primary-color);
    border-radius: 0.2rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    color: white;
    padding: 0.1rem;
  
  }
   
  .expand-button {
    background-color: var(--focus-color);
    border: none;
    color: white;   
    border-radius: 50%;
    font-size: small;
    cursor: pointer;
    display: flex;
    
  }
  
 
  .order-details {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    text-align: left;
    font-size: x-small;
    justify-content: space-between;
    background: white;
    color: var(--primary-color);
    padding: 0.5rem 0rem;  
    border-radius: 0rem 0rem 0.5rem 0.5rem;
    border-top: 1px solid var(--focus-color);
    font-size: 0.9rem;
  }
  .btn-round-expand-order{
    display: flex;
    width: 1rem;
    height: 1rem;
    margin-left: 1.5rem;
    color: white;
    background-color: var(--focus-color);
    border-radius: 50%;
  }

  .pagination {
    display: flex;
    flex-direction: row;
    text-align: center;
    position: fixed;
    bottom: 10rem;
    left: 0;
    right: 0;
    margin: 0 auto; 
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .page-button {
    border: none;
    color: white;
    width: 1.5rem;
    height: 1.5rem;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 0.5rem;
    cursor: pointer;
  }
  
  .page-button-arrow{
    background: var(--secondary-color);
    border: none;
    width: 2.5rem;
    height: 2.5rem;
    color: white;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 0.2rem;
    border-radius: 2rem;
    margin: 0rem 1.5rem
  }