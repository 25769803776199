.desktop-notifications-list-container{
    padding: 1rem;
    display: flex;
    flex-direction: column;
    margin-right: 0.5rem;   
}  

.desktop-notifications-list-container .desktop-title-wrapper{
 text-transform: uppercase;
 color: var(--primary-color);
} 

  .desktop-notifications-row {
    background: white;
    border: 1px solid var(--background-color);   
    align-items: center;
    cursor: pointer;
    transition: background-color 0.2s;
    border-radius: 0.5rem;
    color: var(--text-color);
    margin-bottom: 0.2rem;
    color: var(--primary-color);
    border: 1px solid var(--background-color);
  }
  
  .desktop-notifications-row:hover {
    border: 1px solid var(--focus-color);
  }

  .desktop-notifications-row .active {
    border: 1px solid var(--focus-color);
  }
  
  .desktop-notifications-summary {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0.15rem 0rem;
    font-size: small;

  }

 
  
  .desktop-notifications-summary-left {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    font-size: small;
    padding: 0.5rem;
  }
  
  .desktop-notifications-summary-right {
    display: flex;   
    justify-content: center;  
    background: none;   
    padding: 0.3rem 0rem;    
  }

  .desktop-notifications-summary-quantity{
    background: var(--primary-color);
    border-radius: 0.2rem;
    display: flex;
    flex-direction: column;
    text-align: center;
    color: white;
    padding: 0.1rem;
  
  }
   
  .desktop-notifications-summary-right .desktop-btn-round-expand {
    background-color: var(--focus-color);
    border: none;
    color: white;   
    border-radius: 50%;
    font-size: small;
    cursor: pointer;
    display: flex;    
  }
  
 
  .desktop-notifications-details {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    text-align: left;
    font-size: x-small;
    background: white;
    color: var(--primary-color);
    padding: 1.5rem 0rem;  
    border-radius: 0rem 0rem 0.5rem 0.5rem;
    border-top: 1px solid var(--focus-color);
    font-size: 0.9rem;
  }

  .desktop-notification-content{
    padding: 1rem 0.5rem;
  }
  

  .desktop-pagination {
    display: flex;
    flex-direction: row;
    text-align: center;
    position: fixed;
    bottom: 2rem;
    left: 0;
    right: 0;
    margin: 0 auto; 
    justify-content: center;
    align-items: center;
  }
  
  .desktop-page-button {
    border: none;
    color: var(--primary-color) !important;
    width: 1.5rem;
    height: 1.5rem;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 0.5rem;
    background: none !important;
  }

  .desktop-page-button.active{
    background: var(--focus-color) !important;
    color: white !important;
  }

  .desktop-new-notifications{   
    border: 1px solid var(--secondary-color);
    background: "lightgrey";
    color: var(--primary-color);
  }
  
  
  .desktop-page-button-arrow{
    background: var(--secondary-color);
    border: none;
    width: 2rem !important;
    height: 2rem !important;
    color: white;
    justify-content: center;
    align-items: center;
    display: flex;
    padding: 0.1rem;
    border-radius: 2rem;
    margin: 0rem 1.5rem;
    cursor: none;
  }

  .desktop-page-button-arrow:disabled{
    background-color: lightgrey;
  }

  .fade-in {
    opacity: 0;
    animation: fadeIn ease 1s forwards;
  }

  .new-notification-symbol{
    width: 2rem;
    height: 1rem;
    background-color: var(--primary-color);
    color: white;
    font-size: x-small;
    border-radius: 0.5rem;
    margin-left: 1rem ;
  }
  
  
  @keyframes fadeIn {
    100% {
      opacity: 1;
    }
  }

  /* Enhance the visual hierarchy */
.desktop-notifications-summary-left div,
.desktop-notifications-summary-right div {
  font-weight: bold; /* Make certain texts bolder */
}

/* Improved spacing and padding */
.desktop-notifications-row {
  margin-bottom: 0.5rem; /* Increase spacing between rows */
  padding: 0.05rem; /* Increase padding for touch targets */
}


/* Smooth transitions for expanding and collapsing */
.desktop-notifications-details {
  max-height: 500;
  overflow: hidden;
  transition: max-height 0.3s ease;
  text-align: left;
}



/* Consistent iconography */
.btn-round-expand {
  transition: transform 0.3s ease; /* Smooth rotation transition */
}

.desktop-notification-row.expanded .btn-round-expand {
  transform: rotate(180deg); /* Rotate icon when expanded */
  border: 1px solid var(--focus-color);
}

