.conversion-rate-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: white;
    padding: 20px;
    border-radius: 8px;
    margin: 0rem 1rem;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1);
  }
  
  .conversion-rate-form .form-group {
    margin-bottom: 15px;
  }
  
  .conversion-rate-form .form-group label {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;
  }
  
  .conversion-rate-form .form-group select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .conversion-rate-form .form-actions {
    display: flex;
    flex-direction: column;
    align-items: baseline;
    border: 1px solid var(--focus-color);
 
  }
  
 .settings-input-group{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
 }

 .settings-input-group{
  padding-right: 1.5rem;
 }

 .settings-table{
    width: 100%;
    text-align: left;
    table-layout: fixed;
 }

 .conversion-rate-container button:disabled{
  cursor: not-allowed;
  background: lightgray;
 }
  

 .action-cell {
  display: flex;
  justify-content: flex-end;
}


.tab-list {
  list-style-type: none;
  padding: 0;
  display: flex;
  margin: 0 1rem;
  border-radius: 0.5rem 0.5rem 0 0;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
}

.tab-list li {
  padding: 10px 20px;
  margin-left: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.tab-list li:hover {
  background-color: var(--focus-color);
  color: white
}

.tab-list .active {
  background-color: var(--focus-color);
  color: white;
  border-bottom: 2px solid #444;
  border-radius: 0;
}

.tab-content {
margin: 0;
padding:0;
  border-top: none;
}

.form-group option:disabled{
  background: lightgray;
  cursor: not-allowed;
  color: white;
} 
