.admin-market-wrapper {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 1.5rem;
}

::-webkit-scrollbar{
    display: none;
}

.admin-card-table-body{
  background-color: white;
  width: 100%;
  padding: 0.7rem;
  border-radius: 0rem 0rem 0.5rem 0.5rem;
  height: calc(100% - 50px);
  transition: all 0.3s ease; 
}

.admin-market-group {
  margin-top: 0.5rem !important;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(140px, 5fr)); /* Adjust the minmax values based on your card size */
  grid-gap: 2rem !important;
  grid-auto-columns: auto;
  width: 100%;
  text-align: center;
  transition: all 0.3s ease; 
}

.admin-product-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 8rem; /* Adjust as necessary */
  height: 9rem; /* Adjust as necessary */
  padding: 0.5rem;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  border: 1px solid white;
}

.admin-product-card.selected{
  border: 1px solid var(--focus-color)
}

.admin-product-card:hover {
  transform: translateY(-5px);
  border: 1px solid var(--focus-color);
  transition: all 0.3s ease; 
}

.admin-product-card .type,
.admin-product-card .price {
  margin: 5px 0;
  font-size: small;
}

.admin-default-button.admin-market-btn {
  width: 100%;
  text-align: center;
  padding: 0.5rem 0;
}

.spinner-container-market{
    position: absolute;
    top: 50%;
    left: 50%;
}

.product-detail-card {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: stretch;
    transition: all 0.1s ease; 
    
    height: calc(33vh); 

  }
  
  .product-image-container {    
    height: 94%; 
    align-items: center;
    justify-content: center;
    place-content: center;
    position: relative;
    border-radius: 0.5rem;
    text-align: center;
    align-items: center;
    vertical-align: middle;
    border: 1px solid var(--secondary-color);
    margin-right: 3rem;
  }
  
  .product-info-container {
    display: flex;
    flex-direction: column; 
  }

  .product-info-container .market-input-group {
    display: flex;
    flex-direction: column;
    flex-grow: 1; /* This allows the input group to fill the space */
  }

  
  .product-info-container .market-input-group textarea {
      height: 100%; /* This will make the textarea fill the input group space */
      resize:none; /* Optional: Prevents manual resizing */
      margin-bottom: 1rem;
    }
    .product-info-container .market-input-group:last-child{
      margin-bottom: 0;
      padding: 0;
    }
  .product-info-container .market-input-group label {
    display: block;
    text-align: left;
    text-transform: uppercase;
    margin-bottom: 5px;
  }
  
  .product-info-container input[type="text"],
  .product-info-container input[type="number"],
  .product-info-container textarea {
    width: 100%;
    padding: 8px;
    border: 1px solid var(--secondary-color);
    background-color: white;
    background: none;
    border-radius: 4px;
    box-sizing: border-box; /* Prevents the inputs from extending beyond their containers */

  }
  
  .product-info-container input[type="text"]:disabled,
  .product-info-container input[type="number"]:disabled,
  .product-info-container textarea:disabled {
    background-color: #e9e9e9;
  }
  
  .product-info-container input[type="text"]:focus,
  .product-info-container input[type="number"]:focus,
  .product-info-container textarea:focus {
   
    outline: 0;
  }
  
  .no-image {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f2f2f2;
    height: 100%;
  }

  .market-group-btn{
    padding: 0.4rem 0.5rem;
    background: var(--focus-color);
    color: "white";
    align-items: center;
  }

  .market-group-btn:hover{
    background: var(--focus-dark-color);
  }

  
  
  .product-action-buttons {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 0;
    right: 4rem;
  }

  .product-action-buttons button{
    padding: 0.2rem;
    margin-top: 0.5rem;
  }

  .product-action-buttons .btn-cancel{
    background: var(--secondary-color);
  }

  .product-action-buttons .btn-save{
    background: var(--focus-color);
  }

  .product-action-buttons .btn-cancel{
    background: var(--secondary-color);
  }

  .product-action-buttons .btn-save{
    background: var(--focus-color);
  }

  .product-action-buttons button:disabled{
    background: #e9e9e9;
  }
  
 
  .product-info-container input:disabled,.product-info-container textarea:disabled {
    background-color: none
  }
  
  .product-info-container input:enabled, .product-info-container textarea:enabled {
    border: 1px solid var(--focus-color)
  }
  
  .add-new-product {
    color: var(--focus-color);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3rem; /* Large + symbol */
    cursor: pointer;
    border: 1px solid var(--focus-color);
  }
  
  .add-new-product:hover {
    background: var(--focus-color);
    color: white;
   
  }

  .success-message {
    background-color: #4caf50; /* Green background color */
    color: white; /* White text color */
    text-align: center;
    padding: 10px; /* Add some padding */
    margin-top: 10px; /* Add some top margin */
  }
  
  .market-list-table {
    width: 100%;
    height: 100%;
    border-collapse: collapse; /* Remove space between table cells */
  }
  
  .market-list-table thead {
    position: sticky; /* Makes the header stick to the top */
    top: 0; /* Zero distance from the top of the container */
    z-index: 10; /* Ensure the header is above other content when scrolling */
   background-color: white; /* Set a background color */

  }
  
  .market-list-table thead th {
    padding: 12px; /* Add more padding for header cells */
    text-align: left; /* Align text to the left */
  }
  
  .market-list-table tbody tr {
    border-bottom: 1px solid #ddd; /* Add a bottom border to table rows */
  }
  
  .market-list-table tbody tr:nth-child(odd) {
    background-color: #f9f9f9; /* Lighter row color for odd rows */
  }
  
  .market-list-table tbody tr:nth-child(even) {
    background-color: white; /* White row color for even rows */
  }
  
  .market-list-table tbody tr:hover {
    background-color: lighten(var(--focus-color), 20%); /* Color when hovering over rows */
    cursor: pointer; /* Change cursor to pointer to indicate interactivity */
  }
  
  .market-list-table tbody td {
    padding: 8px; /* Add padding to cells */
    vertical-align: middle; /* Center cell content vertically */
  }
  
  .market-list-table tbody td img {
    width: 50px; /* Adjust size as necessary */
    height: auto; /* Keep the aspect ratio */
  }
  
  .market-list-table tbody td button {
    padding: 5px 10px; /* Padding inside the button */
    background-color: var(--focus-color); /* Button background color */
    color: white; /* Text color for button */
    border: none; /* Remove border */
    border-radius: 5px; /* Round corners of the button */
    cursor: pointer; /* Change cursor to pointer to indicate interactivity */
  }
  
  .market-list-table tbody td button:hover {
    background-color: var(--primary-color); /* Button hover effect */
  }
  
  .market-list-table thead th:last-child {
    text-align: right; /* Align the header of the last column to the right */
  }

  .market-list-table tbody td:last-child {
    text-align: right; /* Align the header of the last column to the right */
  }

  .btn-container{
    width: 20%;
    display: flex;
    justify-content: space-around;
  }

  .product-image-wrapper{
    height: 3rem;
    overflow: hidden;
  }

  .admin-product-card span[rate="true"]{
    position: relative;
    right: 0rem;
    background: #4caf50;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
  }

  .admin-product-card span[rate="false"]{
    background: rgb(126, 21, 21);
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
  }
  .table-scroll-container {
    overflow-y: auto; /* Adiciona scroll vertical se necessário */
  }
  