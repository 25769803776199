.order-details-container {
    width: 96%;
    height: 83vh;
    margin-right: 10 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .order-details-container h2 {
    text-align: left;
    margin-bottom: 30px;
    text-transform: uppercase;
    color: var(--primary-color)
  }
  
  .order-info {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* This divides the container into three columns */
    gap: 20px;
  }
  
  .order-info > div {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
  }
  
  .order-info h3 {
    margin-top: 0;
    color: #333;
    font-size: 1.2rem;
  }
  
  .order-info p {
    color: #666;
    margin: 5px 0;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .order-info {
      grid-template-columns: 1fr; /* Stack the columns on smaller screens */
    }
  }
  
  
  /* For example, to highlight the total amount */
  .order-total {
    background-color: #eaf8e0;
    font-weight: bold;
  }
  
  /* For example, if you have status colors */
  .order-status p {
    padding: 2px 5px;
    border-radius: 4px;
    text-decoration: none;
    display: flex;
  }
  
  .order-status p:before {
    content: "•";
    display: inline-block;
    margin-right: 5px;
  }
  
  /* You can add a specific color depending on the order status */
  .order-status p[data-status="Processing"] {
    color: #ff9900;
  }
  
  .order-status p[data-status="Completed"] {
    color: #4bb543;
  }
  
  .order-status p[data-status="Cancelled"] {
    color: #e02424;
  }
  
  /* Feedback style */
  .order-feedback {
    background-color: #ffefef;
  }
  
  /* You could add more styles for hover effects, transitions, etc. */
  .boolean-field {
    display: flex;
    height: 9rem;
    padding: 1.5rem;
    flex-direction: column;
    margin-bottom: 10px; /* Spacing between the fields */
    background-color: white;
    border-radius: 0.5rem;
    text-align: right;
    justify-content: space-between;
    gap: 4px;
    
  }
  
  .boolean-field span {
    margin-left: 1rem; /* Spacing between label and indicator */
  }
  
  .status-indicator {
    padding: 2px 8px;
    border-radius: 10px;
    color: white;
    font-size: 0.85em;
    font-weight: bold;
  }
  
  .yes {
    background-color: #4CAF50; /* Green for On */
  }
  
  .no {
    background-color: var(--focus-color); /* Red for Off */
  }

    .customer-info>h3,
    .order-status>h3,
    .product-info>h3,
    .product-info>h3,
    .order-total>h3,
    .order-feedback>h3 {
      color: var(--secondary-color);
    }
