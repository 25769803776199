.settings{
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0rem 1rem ;
}

.settings > label{
    display: flex;
    flex-direction: row;
}

.simulate-btn{
    width: 8.5rem;
    background: var(--focus-color);
    padding: 0.5rem 0.1rem;
    margin-left: 2rem;
}


  
  .simulation-step {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 20px;
    margin-bottom: 20px;
  }
  
  .simulation-step h2 {
    color: #333;
    margin-bottom: 15px;
  }
  
  /* Further style your components (buttons, text, etc.) according to your design */
  .bolota-card {
    background: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.1);
    margin-bottom: 20px;
    padding: 20px;
  }
  
  .settings {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
  }
  
  .settings div {
    flex: 1;
    margin-right: 10px;
  }
  
  .settings label {
    display: block;
    margin-bottom: 5px;
    color: #333;
    font-size: 14px;
  }
  
  .settings select, .settings input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  

  
  .simulate-btn:hover {
    background-color: #e68a00;
  }
  
  .producer-selection-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .producer-card {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f9f9f9;
  }
  
  .producer-info p {
    margin: 5px 0;
  }
  
  .select-producer-btn {
    padding: 10px 20px;
    background-color: var(--secondary-color); /* Green */
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    margin: 4px 2px;
    transition-duration: 0.4s;
    cursor: pointer;
    border-radius: 5px;
  }
  
  .select-producer-btn:hover {
    background-color: var(--focus-color);
  }

  .selected-step-info{
    display: flex;
    border: 1px solid #ccc;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
    padding: 1rem;
    justify-content: space-around;
  }
  