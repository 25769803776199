.register-user-container{
    display: flex;
    flex-direction: row;
    width: 70%;
  }
  
  .title-register-wrapper{
      color: var(--primary-color);
      font-weight: bold;
      font-size: larger;
  }
  .form-group {
      margin-bottom: 1rem;
      padding-right: 1rem;

    }
    
    label {
      display: block;
      margin-bottom: 0.5rem;
      color: var(--primary-color);
      font-size: small;
    }
    
    .form-group input {
      width: 100%;
      padding: 0.5rem;
      font-size: 1rem;
      border: 0px solid var(--secondary-color); 
      border-radius: 0.25rem;
      color: var(--secondary-color);
      font-size: small;
    }
    
    button {
      padding: 0.75rem 1.5rem;
      font-size: 1rem;
      border: none;
      background-color: var(--primary-color); 
      color: white;
      cursor: pointer;
      border-radius: 0.25rem;
    }
    
    button:hover {
      background-color: var(--focus-color); 
    }
    
    .gps-group-wrapper{
      display: flex;
      flex-direction: row;
    }
  
    .gps-pin-location-button{
      position: relative;
      top:-1.5rem;
      right: -20rem;
      border-radius: 50%;
      width: 1.7rem;
      height: 1.7rem;
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
      padding: 0.1rem;
      background: var(--focus-dark-color);
      box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
    }
  
    .error-message{
      text-align: center;
  
    }
   
  .error-message-slider{
    position:fixed;
    padding: 0.7rem 0rem;
    top: 0;
    left: 0;
    width: 100%;
    background: var(--focus-color);
    color: white;
   border-radius: 0re 0rem 0rem 0rem;
    z-index: 1000;
    transform: translateY(-100%);
    transition: transform 0.3s ease-in-out;
    text-align: center;
    justify-content: center;
    align-items: center;
  }
  
  .error-message-slider.active-error {
    transform: translateY(0);
    animation: slideDown 0.3s ease-out;
  }
  
  .checkbox-group {
    display: flex;
    flex-wrap: wrap;
    gap: 10px; /* Adjust the space between checkboxes as needed */
  }
  
  .checkbox-label {
    display: flex;
    align-items: center;
    gap: 5px; /* Space between checkbox and label text */
  }


 
  
  .register-user-form {
    flex: 1;
    margin-right: 2rem;
  }
  
  .edit-client-container {
    flex: 1;
  }
  .producer-info{
    padding:0;
    margin-left: 2%;
  }

  
  @keyframes slideDown {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  