/*------------ VARIÁVEIS GLOBAIS PARA CORES ---------------*/

:root {
    --primary-color: #5D643F;
    --secondary-color: #8E9475;
    --background-color: #f5f5eb;
    --focus-color: #FFB006;
    --focus-dark-color: #FE7F00;
    --text-color: #333;
    --success-color: #61A375;
    --error-color: #dc3300;
  }
  
  body {
    background-color: var(--background-color);
    color: var(--text-color);
  }


/*------------ DEFINIÇÕES PARA MOBILE SCREENS ---------------*/
  
.top-container {
  height: 8vh;
  background-color: var(--background-color);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem;
}

.main-container {
  height: 78vh;
  background-color: var(--background-color);
  overflow: unset;
  display: flex;
  flex-direction: column;
}

.floating-menu-container {
  height: 15vh;
  background-color: var(--background-color);
  display: flex;
  flex-direction: row;
  text-align: center;
  justify-content: center;
  position: fixed;
  width: 100%;
  padding-top: 0.5rem;
  
}

.title-wrapper{
  color: var(--secondary-color);
  font-size: 2.5rem;
  display: flex;
  justify-content: center;
  text-align: center;
}

/*------------ DEFINIÇÕES PARA DESKTOP SCREENS ---------------*/

.dashboard {
  display: flex;
  flex-direction: column;
}

.navbar-container {
  flex: 0 0 auto;
  background-color: var(--navbar-color);
  z-index: 1;
}

.desktop {
  display: flex;
  flex: 1;
}

.content-container {
  display: flex;
  flex: 1;
  overflow: hidden;
  z-index: 0;
  margin-top: 3.6rem;
}

.sidemenu-container {
  flex: 0 0 auto;
  width: 240px;
  background-color: var(--sidebar-color);
  overflow-y: auto;
  position: fixed;
  top: 4.4rem;
  bottom: 0;
  left: 0;
}

.main-container-desktop {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  background-color: var(--main-content-color);
  margin-left: 240px; /* Adjust the margin to match the width of the sidebar */
  
}

@media (max-width: 768px) {
  .main-container-desktop {
    margin-left: 0.5rem;
    padding: 1rem 1rem;
    padding-right: 0; }
}

.sidemenu-container::-webkit-scrollbar,
.main-container-desktop::-webkit-scrollbar {
  display: none;
}

