.change-password-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .change-password-modal-content {
    background-color: white;
    padding: 1rem;
    border-radius: 8px;
    box-shadow: 0 15px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    color: var(--primary-color);
  }
  
  .modal-title {
    font-size: 1.5rem;
    margin-bottom: 2.5rem;
  }

  .modal-form {   
    text-align: left;
  }
  
  .modal-form input {   
    margin: 5px 0;
    padding: 1rem;
    border: 1px solid var(--background-color);
    width: 90%;
    border-radius: 0.8rem;
    color: var(--secondary-color);
  }
  
  .modal-buttons {
    margin-top: 20px;
  }
  
  .modal-button {
    margin: 0 10px;
  }
  
  .change-password-modal-cancel-btn{
    background-color: var(--focus-color);
    color: white;
    width: 1.5rem;
    height: 1.5rem;
    padding: 0.15rem;
    border-radius: 2rem;
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
    top: -1.8rem;
    left: 17rem;
  }