.user-tables-container-wrapper {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    margin-right: 2rem;
  }
  
  .tables-container {
    margin-bottom: 1rem;
  }
  
  .card-table {
    border: 1px solid var(--background-color);
    border-radius: 0.5rem;
  }
  .selected-row {
   border-color:  5px solid var(--focus-color);
  }
  
  .card-table-header {
    background: var(--primary-color);
    color: white;
    padding: 0.75rem;
    border-radius: 0.5rem 0.5rem 0 0;
  }
  
  .card-table-title {
    font-size: 1.25rem;
  }
  
  .card-table-body {
    padding: 1rem;
  }
  
  .user-table {
    width: 100%;
    border-collapse: collapse;
  }
  

  .user-table td {
    border: 1px solid var(--background-color);
    padding: 0.75rem;
    color:black
  }
  .user-table tr:nth-child(even) {
    background-color: white;
}


  .product {
    padding: 10%;
    /*display: table-row;*/
  }
  
  .product div{
    display: table-cell;
  }
  
  .normal-row {
    background: white;
    color: var(--primary-color);
  }
  
  .expanded-row {
    background: var(--secondary-color);
    color: white;
  }
  
  .expanded-row td {
    padding: 1rem;
  }
  
  .desktop-notification-row {
    background: white;
    border: 1px solid var(--background-color);
    cursor: pointer;
    transition: background-color 0.2s;
    border-radius: 0.5rem;
    color: var(--primary-color);
  }
  
  .desktop-notification-row:hover {
    border: 1px solid var(--focus-color);
  }
  
  .desktop-notification-row.expanded {
    border: 1px solid var(--focus-color);
  }
  
  .desktop-notification-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.75rem;
  }
  
  .desktop-notification-summary-left,
  .desktop-notification-summary-right {
    display: flex;
    align-items: center;
  }
  
  .desktop-notification-summary-left div,
  .desktop-notification-summary-right div {
    font-weight: bold;
  }
  
  .btn-round-expand {
    background-color: var(--focus-color);
    border: none;
    color: white;
    border-radius: 50%;
    font-size: 1rem;
    cursor: pointer;
    padding: 0.5rem;
  }
  
  .desktop-notification-details {
    padding: 1rem;
    background: white;
    color: var(--primary-color);
    border-top: 1px solid var(--focus-color);
    border-radius: 0 0 0.5rem 0.5rem;
  }
  
  .desktop-notification-content {
    padding: 1rem 0;
  }
  
  .desktop-pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  }
  
  .desktop-page-button {
    border: none;
    background: var(--primary-color);
    color: white;
    width: 2rem;
    height: 2rem;
    margin: 0 0.25rem;
    border-radius: 0.25rem;
    cursor: pointer;
  }
  
  .desktop-page-button-arrow {
    border: none;
    background: var(--primary-color);
    color: white;
    width: 3rem;
    height: 3rem;
    margin: 0 0.5rem;
    border-radius: 50%;
    cursor: pointer;
  }
  
  .fade-in {
    opacity: 0;
    animation: fadeIn ease 1s forwards;
  }
  
  @keyframes fadeIn {
    100% {
      opacity: 1;
    }
  }
  