.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--secondary-color);
    padding: 0px 20px;
    padding-top: 5px;
    color: white;
    position: fixed;
    width: 100%;
  }
  
  .navbar-logo {
    font-size: 24px;
    color: white;
  }
  
  .navbar-user {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin-right: 3rem;
  }

  .navbar-bell{
    display: flex;
    margin-right: 1.5rem;
    position: relative;
  }

  .navbar-bell .navbar-bell-messages-indicator{
    position: absolute;
    top: 0;
    right:-0.35rem;
    width: 1rem;
    text-align: center;
    align-items: center;
  font-size: x-small;
    height: 1rem;
    background: var(--focus-dark-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
  }


  
  .user-dropdown {
    position: absolute;
    right: 3rem;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: none;
    transition: transform 0.2s;
    transform: scaleY(0);
    transform-origin: top center;
  
  }
  
  
  .user-dropdown.active {
    display: block;
    transform: scaleY(1);
    background: none;
    box-shadow: none;
  }
  
  .user-dropdown ul {
    list-style: none;
    background-color: var(--secondary-color);
    border: 2px solid var(--focus-color);
    position: absolute;
    top: 1.5rem;
    right: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px 10px;
    display: none;
  }
  
  .user-dropdown.active ul {
    display: block;
  }
  
  .user-dropdown ul li {
    padding: 5px 30px;
    cursor: pointer;
   
  }

  .user-dropdown li:hover{ 
    background: var(--focus-dark-color);
    border-radius: 0.2rem;   
  }

  .user-icon-toggle{
    background: var(--focus-dark-color);
    margin-right: 0.8rem;
    border-radius: 50%;
    padding: 0.1rem;
  }

  .user-dropdown-divider{
    border-bottom: 1px solid var(--focus-color);
    padding: 0;
    margin: 0;
  
  }

  