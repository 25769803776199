.admin-profile {
    max-width: 96%;
    margin: auto;
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    font-size: small;
    gap: 15px;
 }
 
  
  .admin-profile h1 {
    text-align: left;
    color: #333;
    margin-bottom: 30px;
  }
  
  .admin-profile form {
    display: flex;
    flex-direction: column;
    justify-content: space-around;   
    gap: 5px;
  }

  .admin-edit-btn{
    position: relative;
    top:0;
    left: -25rem;
    font-size: x-small !important;
  }
  
  .admin-profile label {
    display: flex;
    flex-direction: column;
    color: var(--secondary-color);
    margin-bottom: 5px;
    font-size: small;
  }
  
  .admin-profile input[type="text"],
  .admin-profile input[type="email"],
  .admin-profile input[type="password"] {
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
    font-size: 16px;
    font-size: x-small;
  }
  
  .admin-profile button {
    padding: 10px;
    background-color: var(--secondary-color);
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 18px;
    transition: background-color 0.3s;
  }
  
  .admin-profile button:hover {
    background-color: var(--focus-color);
  }
  
  .admin-profile {
    display: flex;
    flex-direction: column;
    height: calc(100vh - var(--header-footer-height)); /* Adjust for your header/footer */
  }
  
  .admin-table-container {
    flex-grow: 1; /* Fill the remaining height */
    overflow-y: auto; /* Make it scrollable */
  }
  
  .admin-table {
    width: 100%; /* Full width */
    border-collapse: collapse; /* Clean table look */
  }
  
  .admin-table th,
  .admin-table td {

    padding: 8px; /* Padding for cells */
  }
  
  .admin-table th {
    background-color: #f4f4f4; /* Header background */
    position: sticky;
    top: 0; 
    text-align: left;
  }

  .admin-table th:last-child,
  .admin-table td:last-child{
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 8rem;
  }
  
  .admin-table td button {
    margin-bottom: 8px; /* Space between buttons */
    width: 5rem;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: small;
    padding: 0.2rem;
  }

  .admin-table td button:first-child {
   background-color: var(--focus-color);
  }
  
  .admin-table td button:first-child:hover {
    background-color: var(--primary-color);
   }
   
  
  