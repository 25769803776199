
.map-container {
    display: flex;
    background: white;
    border-radius: 1rem;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 115px);
    border: 2px solid white;
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1);
}




.dashboard-tables-container {
  display: flex;
  flex-direction: column;     
  padding-top:0;
  padding: 0;
  margin-left: 0.5rem;
  height: calc(100vh - 115px);
  justify-content: space-between;
 
}

.dashboard-card-table{
  height: calc((100vh - 10rem)/3);
  background: white;
  border-radius: 1rem;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1);
  
}



.dashboard-card-table-header{
  background: var(--secondary-color);
  text-align: left;
  color: white;
  border-radius: 1rem 1rem 0rem 0rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.2rem 0rem;
  margin-bottom: 1rem;


}

.dashboard-card-table-body {
    font-size: small;
    width: 100%;
    overflow-y: auto; 
    
  }
table.dashboard-user-table {
  table-layout: fixed; /* Garante que todas as colunas respeitem o layout fixo */
  width: 100%; /* Garante que a tabela ocupe a largura total do container */
}

.dashboard-user-table thead th,
.dashboard-user-table tbody td {
  text-align: left;
  padding: 2px 10px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
  
  .category-wrapper {
    display: flex;
    flex-direction: row;
    /* Justificar conforme necessário */
  }

.species-span {
    display: flex;
    border-radius: 50%;
    color: white;
    width: 16px; /* Adjust size as needed */
    height: 16px; /* Adjust size as needed */
    margin-right: 5px;
    font-size: x-small;
    text-align: center;
    justify-content: center;
    align-items: center;
    padding: 0;
    line-height: 20px; /* Adjust line height to match height for vertical alignment */
  }
  
 
  