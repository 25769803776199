.login-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

.login-input-group {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center; 
}
  
.login-input-group-item{
    width: 14rem;
    border: none;
    padding: 0.7rem;
    color: var(--secondary-color);     
    border-radius: 0.5rem;
}

  
input:focus {
    border-color: var(--focus-color); 
    outline: 1px; 
    box-shadow: 0 0 0 2px var(--focus-color);
}  

.forgot-password-wrapper{
    font-size: small;
    text-align: center;
    justify-content: center;
}

.register-link-item{
    color: var(--focus-color);
    font-weight: bold;
}

  
.default-button {
    background-color: var(--focus-color);
    color: white;
    padding: 10px 60px;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    margin-top: 2rem;
    font-size: small;
}

.default-button:hover{
    transform: scale(1.01);
}

.error-message-wrapper{
    padding-top: 1.5rem;
}
.error-message{
    background: var(--focus-dark-color);
    color: white;
    padding: 0.3rem 2.3rem;
    font-size: small;
    border-radius: 0.3rem
}

.fade-in {
    animation: fadeIn 0.5s ease-in-out;
  }

  .footer-developer{
    position: absolute;
    /*bottom: 3rem;*/
  }
  
  @keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(-15px);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }