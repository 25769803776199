
  .map-wrapper {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    position: relative;
    border-radius: 1rem;
    z-index: 0;
  }

  .map-url-contributor{
    color: var(--primary-color);
    text-decoration: none;
  }
  

  .leaflet-popup {
    border-radius: 1rem;   
    text-align: center;
    justify-content: center;
    background: var(--secondary-color);
    padding: 0.2rem;
  }

.leaflet-popup-close-button span { 
  background-color: var(--focus-dark-color);
  color: white;
  border: none;
  border-radius: 50%;
  width: 0.5rem;
  height: 0.5rem;
  display: flex;
  padding: 0.5rem;
  justify-content: center;
  align-items: center;
  position: relative;
  top: -0.5rem;
  right: 0.8rem;
}

.leaflet-popup-close-button span:hover {
  background-color: var(--focus-color);
}

  
 
  .leaflet-popup-content {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 0.25rem;
    margin: 0.5rem;
  }

  .leaflet-popup-content-wrapper {  
    margin-top: 1.25rem;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
   
  }

  .leaflet-popup-body {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 0rem;
  }
  
 
  .custom-marker-icon {
    width: 20px;
    height: 20px;
    background-color: #FFB006;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 14px;
    font-weight: bold;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }

  #map1 .leaflet-tile-pane {
    /* -webkit-filter: grayscale(100%) brightness(1.2) saturate(1.2); */
    filter: grayscale(100%) brightness(1.15) saturate(0.5);
}


.map-popup-wrapper{
  background-color: white;
  padding: 0.5rem;
  justify-content: center;
}

.info {
  padding: 6px 8px;
  font: 14px/16px Arial, Helvetica, sans-serif;
  background: white;
  background: rgba(255,255,255,0.8);
  box-shadow: 0 0 15px rgba(0,0,0,0.2);
  border-radius: 5px;
}
.info h4 {
  margin: 0 0 5px;
  color: #777;
}