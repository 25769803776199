.stock-bolota-container {
  width: 100%;
  overflow-x: auto; /* Habilita o scroll horizontal */
}

.stock-bolota-header,
.stock-bolota-item {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid #ccc;
}

.stock-bolota-header-cell{
  width: 10%;
  text-align: left;
  padding: 5px;
  margin-right: 5px;
}
.stock-bolota-header-cell:nth-child(5) ,
.stock-bolota-cell:nth-child(5) {
  width: 35%; /* Altere o valor para o desejado */
  text-align: left; /* Ajuste o alinhamento, se necessário */
}
.stock-bolota-header-cell:nth-child(6) ,
.stock-bolota-cell:nth-child(6) {
  width: 10%; /* Altere o valor para o desejado */
  text-align: left; /* Ajuste o alinhamento, se necessário */
}
.stock-bolota-header-cell:nth-child(3) ,
.stock-bolota-cell:nth-child(3) {
  width: 10%; /* Altere o valor para o desejado */
  text-align: left; /* Ajuste o alinhamento, se necessário */
}
.stock-bolota-header-cell:nth-child(4) ,
.stock-bolota-cell:nth-child(4) {
  width: 5%; /* Altere o valor para o desejado */
  text-align: left; /* Ajuste o alinhamento, se necessário */
}

.stock-bolota-header-cell:last-child ,
.stock-bolota-cell:last-child {
  width: 5%; /* Altere o valor para o desejado */
  text-align: left; /* Ajuste o alinhamento, se necessário */
}
.stock-bolota-header-cell:first-child,
.stock-bolota-cell:first-child {
  width: 15%; /* Altere o valor para o desejado */
  text-align: left; /* Ajuste o alinhamento, se necessário */
}
.stock-bolota-cell {
  width: 10%;
  text-align: left;
  padding: 5px;
}

.stock-bolota-header {
  font-weight: bold;
}


  .warehouse-history-container {
    width: 90%;
    text-align: left; /* Alinhamento à esquerda */
  }
  
  .warehouse-history-header, .warehouse-history-item {
    display: flex;
    justify-content: flex-start; /* Garante alinhamento à esquerda */
    padding: 10px 0;
    border-bottom: 1px solid #ddd; /* Linha inferior para separar itens */
  }
  
  .warehouse-history-header {
    font-weight: bold;
    color: #333; /* Cor do texto do cabeçalho */
    background-color: #f2f2f2; /* Fundo mais claro para destaque */
    padding: 15px 0;
  }
  
  .warehouse-history-cell {
    flex: 1; /* Garante que cada célula ocupe o mesmo espaço */
    padding: 10px;
    width: 100%;
  }
  @media (max-width: 768px) {
    /* Quando a largura da tela for menor que 768px */
    .stock-bolota-container {

      display: block; /* Faz o contêiner da tabela se comportar como um bloco */
      overflow-x: auto; /* Ativa o scroll horizontal */
      -webkit-overflow-scrolling: touch; /* Suaviza o scroll em dispositivos móveis */
    }
  
  

  }
  

  