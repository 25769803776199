.admin-market-wrapper {

    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 1.5rem;
  }
  
  ::-webkit-scrollbar{
      display: none;
  }
  
  .admin-card-table-body{
    background-color: white;
    width: 100%;
    padding: 0.7rem;
    border-radius: 0rem 0rem 0.5rem 0.5rem;
    height:100%;
  }
  
  .admin-market-group {
      margin-top: 1rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr)); /* Adjust the minmax values based on your card size */
    grid-gap: 1rem;
    grid-auto-columns: auto;
    width: 100%;
    text-align: center;
  }
  
  .admin-product-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 8rem; /* Adjust as necessary */
    height: 100%; /* Adjust as necessary */
    padding: 0.5rem;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
    border: 1px solid white;
  }
  
  .admin-product-card:hover {
    transform: translateY(-5px);
    border: 1px solid var(--focus-color);
  }
  
  .admin-product-card .type,
  .admin-product-card .price {
    margin: 5px 0;
    font-size: small;
  }
  
  .admin-default-button.admin-market-btn {
    width: 100%;
    text-align: center;
    padding: 0.5rem 0;
  }
  
  .spinner-container-market{
      position: absolute;
      top: 50%;
      left: 50%;
  }
  
  .product-detail-card {
      position: relative;
      display: flex;
      flex-direction: row;
      align-items: stretch;
      
      height: 100%; 
  
    }
    
    .product-image-container {    
      height: 94%; 
      align-items: center;
      justify-content: center;
      place-content: center;
      position: relative;
      border-radius: 0.5rem;
      text-align: center;
      align-items: center;
      vertical-align: middle;
      border: 1px solid var(--secondary-color);
      margin-right: 3rem;
    }
    
    .product-info-container {
      display: flex;
      flex-direction: column; 
    }
  
    .product-info-container .market-input-group {
      display: flex;
      flex-direction: column;
      flex-grow: 1; /* This allows the input group to fill the space */
    }
  
    
    .product-info-container .market-input-group textarea {
        height: 100%; /* This will make the textarea fill the input group space */
        resize:none; /* Optional: Prevents manual resizing */
        margin-bottom: 1rem;
      }
      .product-info-container .market-input-group:last-child{
        margin-bottom: 0;
        padding: 0;
      }
    .product-info-container .market-input-group label {
      display: block;
      text-align: left;
      text-transform: uppercase;
      margin-bottom: 5px;
    }
    
    .product-info-container input[type="text"],
    .product-info-container input[type="number"],
    .product-info-container textarea {
      width: 100%;
      padding: 8px;
      border: 1px solid var(--secondary-color);
      background-color: white;
      background: none;
      border-radius: 4px;
      box-sizing: border-box; /* Prevents the inputs from extending beyond their containers */
  
    }
    
    .product-info-container input[type="text"]:disabled,
    .product-info-container input[type="number"]:disabled,
    .product-info-container textarea:disabled {
      background-color: #e9e9e9;
    }
    
    .product-info-container input[type="text"]:focus,
    .product-info-container input[type="number"]:focus,
    .product-info-container textarea:focus {
     
      outline: 0;
    }
    
    .no-image {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f2f2f2;
      height: 100%;
    }
  
    
    
    .product-action-buttons {
      position: absolute;
      display: flex;
      flex-direction: column;
      top: 0;
      right: 4rem;
    }
  
    .product-action-buttons button{
      padding: 0.2rem;
      margin-top: 0.5rem;
    }
  
    .product-action-buttons .btn-cancel{
      background: var(--secondary-color);
    }
  
    .product-action-buttons .btn-save{
      background: var(--focus-color);
    }
  
    .product-action-buttons .btn-cancel{
      background: var(--secondary-color);
    }
  
    .product-action-buttons .btn-save{
      background: var(--focus-color);
    }
  
    .product-action-buttons button:disabled{
      background: #e9e9e9;
    }
    
   
    .product-info-container input:disabled,.product-info-container textarea:disabled {
      background-color: none
    }
    
    .product-info-container input:enabled, .product-info-container textarea:enabled {
      border: 1px solid var(--focus-color)
    }
    
    .add-new-product {
      color: var(--focus-color);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 3rem; /* Large + symbol */
      cursor: pointer;
      border: 1px solid var(--focus-color);
    }
    
    .add-new-product:hover {
      background: var(--focus-color);
      color: white;
     
    }
  
    .success-message {
      background-color: #4caf50; /* Green background color */
      color: white; /* White text color */
      text-align: center;
      padding: 10px; /* Add some padding */
      margin-top: 10px; /* Add some top margin */
    }

    .table-scroll-container{
        height:100%;
    }
    
    