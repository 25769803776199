.market-desktop-container {
    display: flex;
    justify-content: space-between;
  }
  
  .market-product-selection {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1rem;
    
    height: calc(85vh);
    overflow: scroll;
    padding-bottom: 2rem;
   
  }
  
  .market-product-card {
    display: flex;
    flex-direction: column;
    background:white;
    width: 80%;
    /*height: 10rem;*/
    justify-content: space-between;
    padding: 0.5rem;
    border-radius: 0.5rem;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border: 1px solid transparent;
    cursor: pointer;
  }

  .market-product-card:hover{
    border: 1px solid var(--focus-color)
  }

  .market-product-card.selected{
    border: 1px solid var(--primary-color);
    font-weight: bold;
  }
  
  .market-product-details {
    background:white;
    height: calc(85vh);
    margin: 0rem 1rem;
    border-radius: 0.5rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    padding: 1rem;
    position: relative;
  }
  
  

  .market-product-wrapper{
    padding: 1rem 2rem;
  }
  
  
  .market-quantity-controls{
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: 5rem;
  }
  
  
  .market-quantity-controls .market-input-group {
    display: flex;
    flex-direction: row;
  }
  
  .market-quantity-controls .market-quantity-input{
    width: 4rem;
    height: 4rem;
    font-size: larger;
    text-align: center;
    margin-right: 0.2rem;
    margin-left: 1rem;
    border-radius: 0.5rem;
    border: 1px solid;
  
  }
  
  .market-quantity-controls .market-quantity-buttons{
    display: flex;
    flex-direction: column;
    padding: 2%;
    margin-left: 0.5rem;
  }
  
  .market-quantity-controls .quantity-buttons button {
    width: 1.7rem;
    height: 1.7rem;
    font-size: 1.2rem;
    display: inline-block;
    font-weight: bold;
    border-radius: 50%;
    border: none;
    color: white;
    background: var(--focus-color);
    margin: 0.1rem 0rem;
  
    text-align: center;
    align-items: center;
  }


   
  .market-quantity-button-control {
    background: var(--focus-color);
    cursor: pointer;
    width: 1.7rem;
    height: 1.7rem;
    display: flex; /* Changed from inline-block to flex */
    font-weight: bold;
    border-radius: 50%;
    padding: 0.1rem;
    border: none;
    color: white;
    text-align: center;
    justify-content: center; /* This will work now */
    align-items: center; /* This will work now */
    margin: 0.1rem 0rem;
    font-size: 1.2rem;
    font-weight: bold;
  }
  

  .market-quantity-button-control:hover{
    background-color: var(--secondary-color);
  }
 

  
  .market-market-container-center{
    display: flex;
    flex-direction: column;
    align-items: center;
   justify-content: center;
   padding: 5rem 0rem;
  }

  .market-title-wrapper{
   
    padding: 1rem;
 }
 

 .market-select-buttons{
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom:2rem;
    left:0rem;
    margin:"2rem 2rem"

 }


 .market-select-buttons button {
    margin: 0.2rem 2rem
 }

 .market-select-buttons button:first-child{
    background: var(--focus-color);
 }

 .market-select-buttons button:hover {
    background: var(--secondary-color);
 }

 .market-basket-container{
    background: white;
    border-radius: 0.5rem;
    padding: 1rem;
    margin-bottom: 1rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    justify-content: space-between;
 }

 .market-default-button{
    background: var(--focus-color);
 }

 .market-default-button:hover{
    background: var(--secondary-color);
 }

 .market-basket-list{
    display: flex;
    flex-direction: row;
    padding: 0.4rem 0.3rem;
    justify-content: space-between;
}

.market-basket-list-info{
   
    display: flex;
    flex-direction: column;
    
}

.market-basket-list-info .market-description{
    margin-left: 0.8rem;
}

.market-basket-list-title{
    color: var(--primary-color);
    font-size: larger;
    font-weight: 600;
    margin-top: -0.5rem;
}

.market-basket-list-title span{
    background: var(--focus-color);
    width: 0.3rem;
    height: 1.2rem;
    position: relative;
    top: 0.3rem;
    margin-right: 0.5rem;
    display: inline-block;
    color: var(--focus-color);
}

.market-basket-list-purchase{
    text-align: right;
    margin-top: 1rem;
}

.market-basket-list-purchase .total{
    margin-top: 0.5rem;
    position: absolute;
}

.market-remove-item-btn{
    background: var(--primary-color);
    color: white;
    border: none;
    font-size: medium;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.2rem;
    position: relative;
    top: 0;
}


.market-quantity-item-btn{
    background: var(--focus-color);
    color: white;
    border: none;
    font-size: medium;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.2rem;
    position: relative;
    top: 0;
}

.market-select-button:disabled{
  background: lightgray;
  cursor: not-allowed
}

.market-select-buttons button:disabled{
  cursor: not-allowed;
  background: var(--background-color);
  color: var(--secondary-color);
}
.market-product-title{
  padding: 20px;
  font-size: 20px; 
  font-weight: bold;
}
.market-add-button{
  padding: 10px;
  font-size: 20px; 
}