.bolota-card {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    background: #FFFFFF;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin: 20px;
    padding: 20px;
  }
    
  .bolota-card fieldset {
    border: 1px solid #CCCCCC;
    border-radius: 4px;
    padding: 10px;
    width: 60%;
  }
  
  .form-wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .bolota-card legend {
    padding: 0 5px;
    width: auto;
    color: var(--primary-color);
  }
  
  .bolota-card label {
    display: block;
    margin-bottom: 5px;
    margin-right: 1rem;
    color: var(--primary-color);
  }

  
  
  .bolota-card input,
  .bolota-card select {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border: 1px solid #CCCCCC;
    border-radius: 4px;
  }
  
  .actions {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  
  .actions button {
    padding: 10px;
    margin-bottom: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
  }
  
  .add {
    background-color: var(--focus-color);
    color: white;
  }
  
  .edit {
    background-color: var(--secondary-color);
    color: white;
  }
  
  .remove {
    background-color: var(--primary-color);
    color: white;
  }
  
  /* If using a preprocessor like SASS, you can use functions to darken the color on hover */
  .add:hover,
  .edit:hover,
  .remove:hover {
    filter: brightness(85%);
  }

  
  
  /* Responsive adjustments can be placed within media queries as needed */
  @media (max-width: 768px) {
    .bolota-card {
      flex-direction: column;
    }
  
    .bolota-card form {
      width: 100%;
    }
  
    .actions {
      width: 100%;
    }
  }
  