 .modal-content-loading {
    background-color: #fff;
    padding: 4rem;
    border-radius: 0.5rem;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  }
  